.react-calendar {
    width: 100%;
    max-width: 100%;
    background: rgba(54, 129, 77, 0.85);
    border-radius: 20px;
    overflow: hidden;
    border: 2px solid white;
    font-family: Arial, Helvetica, sans-serif;
    line-height: 1.125em;
    color: white;
}

.react-calendar--doubleView {
    width: 700px;
}

.react-calendar--doubleView .react-calendar__viewContainer {
    display: flex;
    margin: -0.5em;
}

.react-calendar--doubleView .react-calendar__viewContainer > * {
    width: 50%;
    margin: 0.5em;
}

.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.react-calendar button {
    margin: 0;
    border: 0;
    outline: none;
    /*  added  */
    font-size: 20px;
    font-weight: 400;
}

.react-calendar button:enabled:hover {
    cursor: pointer;
}

.react-calendar__navigation {
    height: 44px;
    margin-bottom: 1em;
}

.react-calendar__navigation button {
    font-size: 25px;
    min-width: 44px;
    background: #00000033;
    border-bottom: 1px solid white;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
    background-color: rgba(230, 230, 230, 0.25);
}

.react-calendar__navigation button[disabled] {
    /*background-color: #f0f0f0;*/
    cursor: auto;
}

.react-calendar__month-view__weekdays {
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.75em;
}

.react-calendar__month-view__weekdays__weekday {
    padding: 0.5em;

    border-bottom: 1px solid white;
}

.react-calendar__month-view__weekNumbers {
    font-weight: bold;
}

.react-calendar__month-view__weekNumbers .react-calendar__tile {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.75em;
    padding: calc(0.75em / 0.75) calc(0.5em / 0.75);

}

.react-calendar__month-view__days__day--weekend {
    color: #58d0ff;
}

.react-calendar__month-view__days__day--neighboringMonth {
    color: #b8b8b8;
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
    padding: 2em 0.5em;
}

.react-calendar__tile {
    max-width: 100%;
    text-align: center;
    padding: 0.75em 0.5em;
    background: none;

    cursor: default;
}

.react-calendar__tile:disabled {
    background-color: #043952;
    border-bottom: 1px solid #ffffff;
    border-top: 1px solid #ffffff;
    cursor: default;

    /*color: rgba(0, 0, 0, 0.65);*/
}

/*.react-calendar__tile:enabled:hover,*/
/*.react-calendar__tile:enabled:focus {*/
/*    background-color: #e6e6e6;*/
/*}*/

/*.react-calendar__tile--now {*/
/*    background: #ffff76;*/
/*}*/
/*.react-calendar__tile--now:enabled:hover,*/
/*.react-calendar__tile--now:enabled:focus {*/
/*    background: #ffffa9;*/
/*}*/
.react-calendar__tile--hasActive {
    background: #76baff;
}

.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
    background: #a9d4ff;
}

/*.react-calendar__tile--active {*/
/*    background: #006edc;*/
/*    color: white;*/
/*}*/

/*.react-calendar__tile--active:enabled:hover,*/
/*.react-calendar__tile--active:enabled:focus {*/
/*    background: #1087ff;*/
/*}*/

.react-calendar--selectRange .react-calendar__tile--hover {
    background-color: #e6e6e6;
}
